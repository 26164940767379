import { Button, Dimmer, Grid, Header, Loader, Message, Segment, Table } from 'semantic-ui-react';
import { useUnregisteredBusinessContext } from '../../storage/context/UnregisteredShopsProvider';
import { BusinessCreationData } from '../../models/models';
import { getTimeStringFromFirebaseTimestamp } from '../../common/format';
import { DefaultResponsiveContainer } from '../../components/DefaultResponsiveContainer';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { ConfirmationPopup } from '../../components/ConfirmationPopup';
import { deleteRegistrationRequest, validateABusiness } from '../../api/firebase';

function BusinessDetailsHolder({data}: {data: BusinessCreationData}){
    return (
        <Table fixed>
            <Table.Header>
                <Table.HeaderCell>Business Details</Table.HeaderCell>
                <Table.HeaderCell>Details</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
                {Object.keys(data).map((key) => {
                    if (key === "submitted_at"){
                        return (
                            <Table.Row key={key}>
                                <Table.Cell>{key}</Table.Cell>
                                <Table.Cell>{getTimeStringFromFirebaseTimestamp(data["submitted_at"] as any).toLocaleString()}</Table.Cell>
                            </Table.Row>
                        )
                    }
                    return (
                        <Table.Row key={key}>
                            <Table.Cell>{key}</Table.Cell>
                            <Table.Cell>{data[key as keyof BusinessCreationData] as string}</Table.Cell>
                        </Table.Row>
                    )
                })}
            </Table.Body>
        </Table>   
    )
}

export function UnregisteredBusinessPage() {
    const {submissionid} = useParams();
    const {currentObject, setCurrentSubmissionId, contextLoading} = useUnregisteredBusinessContext();
    const [loading, setLoading] = useState(false);
    const [deletionIsPopupOpen, setDeletionIsPopupOpen] = useState(false);
    const [validateIsPopupOpen, setValidateIsPopupOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (submissionid === undefined) return;
        setCurrentSubmissionId(submissionid);
    }, [setCurrentSubmissionId, submissionid]);

    const handleToDelete = useCallback(() => {
        if (currentObject && currentObject.submission_id){
            setLoading(true);
            deleteRegistrationRequest(currentObject.submission_id)
                .then(() => {
                    setLoading(false);
                    navigate("/businesses");
                }).catch((err) => {
                    setLoading(false);
                    console.log(err);
                });
        } else {
            alert("No submission id");
        }
    }, [currentObject, navigate]);

    const handleValidate = useCallback(() => {
        if (currentObject && currentObject.submission_id){
            setLoading(true);
            validateABusiness(currentObject.submission_id)
                .then((req) => {
                    setLoading(false);
                    if (req.data && req.data.store_id){
                        navigate("/registered_business/" + req.data.store_id);
                    } else {
                        navigate("/businesses");
                    }
                }).catch((err) => {
                    setLoading(false);
                    console.log(err);
                });
        } else {
            alert("No submission id");
        }
    }, [currentObject, navigate]);

    return (
        <DefaultResponsiveContainer>
            <Dimmer active={loading || contextLoading}>
                <Loader size='massive'>Loading</Loader>
            </Dimmer>
            {(!currentObject.submission_id) && 
            <Segment basic>
                <Message negative>
                    The request is currently not available check the request ID.
                </Message>
            </Segment>}
            {(currentObject.submission_id) && <ConfirmationPopup
                open={deletionIsPopupOpen}
                size="large"
                header={`Delete Registration Request : ${currentObject.submission_id}`}
                text="Are you sure you want to delete the registration request?"
                onConfirm={() => {setDeletionIsPopupOpen(false); handleToDelete(); }}
                onCancel={() => setDeletionIsPopupOpen(false)}
                isNegative
            />}
            {(currentObject.submission_id) && <ConfirmationPopup
                open={validateIsPopupOpen}
                size="large"
                header={`Validate Registration Request : ${currentObject.submission_id}`}
                text="Are you sure you want to validate the registration request?"
                onConfirm={() => {setValidateIsPopupOpen(false); handleValidate(); }}
                onCancel={() => setValidateIsPopupOpen(false)}
            />}
            {(currentObject && currentObject._ref) &&  
            <>
                <Segment basic>
                    <Header>
                        Unregistered Business Page
                    </Header>
                    <p>Submission ID: {submissionid}</p>
                    {currentObject && <BusinessDetailsHolder data={currentObject} />}
                </Segment>
                <Segment basic>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <Button 
                                    negative 
                                    onClick={() => setDeletionIsPopupOpen(true)}
                                >Delete Request
                                </Button>
                            </Grid.Column>
                            <Grid.Column>
                                <Button 
                                    positive 
                                    onClick={() => setValidateIsPopupOpen(true)}
                                >Validate
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </>}
        </DefaultResponsiveContainer>
    );
}
