import { Navigate } from 'react-router-dom';
import { useGeneralContext } from '../storage/context/GeneralContext';
import { UnforbiddenPage } from '../screens/UnforbiddenPage';
import { admins } from '../common/constant';

interface ProtectedPageProps{
    page: JSX.Element
}

export default function ProtectedPage({page}: ProtectedPageProps) {
    const {authenticated, currentUser} = useGeneralContext()
    
    if (!authenticated){
        return <Navigate to={"/login"} />
    } else if (authenticated && currentUser && !admins.includes(currentUser?.uid)) {
        return <UnforbiddenPage />
    }
    return page
}
