import { Header, Button, Grid, Segment, Icon } from 'semantic-ui-react';
import { loginWithGoogleFirebase, logoutWithGoogleFirebase } from '../api/firebase';
import { useGeneralContext } from '../storage/context/GeneralContext';
import { DefaultResponsiveContainer } from '../components/DefaultResponsiveContainer';
import { useNavigate } from 'react-router-dom';

export default function LoginPage() {
    const { authenticated } = useGeneralContext();
    const navigate = useNavigate();

    const handleLogin = () => {
        loginWithGoogleFirebase()
            .then(() => {
                navigate("/");
            })
    };

    const handleLogout = () => {
        logoutWithGoogleFirebase();
    };

    return (
        <DefaultResponsiveContainer>
            <Segment basic vertical>
                <Header as="h1">Authentication Page</Header>
                <p>{authenticated ? 'You are authenticated' : 'You are not authenticated'}</p>
                <Grid>
                    {!authenticated &&<Grid.Row>
                        <Grid.Column>
                            <Button 
                                onClick={handleLogin} 
                                style={{width: 200, height: 50}}
                                color="google plus"
                            >
                                <Icon name="google" /> Login with Google
                            </Button>
                        </Grid.Column>
                    </Grid.Row>}
                    {authenticated && <Grid.Row>
                        <Grid.Column>
                        <Button 
                                onClick={handleLogout} 
                                style={{width: 200, height: 50}}
                                color="google plus"
                            >
                                <Icon name="google" /> Logout with Google
                            </Button>
                        </Grid.Column>
                    </Grid.Row>}
                </Grid>
            </Segment>
        </DefaultResponsiveContainer>
    );
}
