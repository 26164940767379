import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Dimmer, Grid, Loader, Segment, Table } from "semantic-ui-react";
import { useRegisteredBusinessContext } from "../../storage/context/RegisteredShopsProvider";
import { DefaultResponsiveContainer } from "../../components/DefaultResponsiveContainer";
import { ConfirmationPopup } from "../../components/ConfirmationPopup";
import { deleteRegisteredBusinessFromPlatform } from "../../api/firebase";
import { RegisteredBusiness } from "../../models/models";

function BusinessDetailsHolder({data}: {data: RegisteredBusiness}){
    return (
        <Table fixed>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Business Details</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {
                    Object.keys(data).map((key) => (
                        <Table.Row key={key}>
                            <Table.Cell>{key}</Table.Cell>
                            <Table.Cell>{data[key as keyof RegisteredBusiness]}</Table.Cell>
                        </Table.Row>
                    ))
                }
            </Table.Body>
        </Table>   
    )
}

export function RegisteredBusinessPage(){
    const {businessid} = useParams();
    const {setCurrentBusinessId, currentObject} = useRegisteredBusinessContext();
    const [loading, setLoading] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (businessid === undefined) return;
        setCurrentBusinessId(businessid);
    }, [businessid, setCurrentBusinessId]);

    const handleSubmit = useCallback(() => {
        if (businessid === undefined) return;
        setLoading(() => true);
        deleteRegisteredBusinessFromPlatform(businessid)
            .then((resp) => {
                setLoading(() => false);
                if (resp.status === "success"){
                    navigate("/businesses");
                } else {
                    alert(resp.message || resp.status || "Unidentified error");
                }
            })
            .catch((err) => {
                alert(err.message);
                setLoading(() => false);
            });
    }, [businessid, navigate]);

    return (
        <DefaultResponsiveContainer>
            <ConfirmationPopup
                open={isPopupOpen}
                size="large"
                header={`Delete Shop : ${businessid}`}
                text="Are you sure you want to delete the shop from the platform ?"
                onConfirm={() => {setIsPopupOpen(false); handleSubmit(); }}
                onCancel={() => setIsPopupOpen(false)}
                isNegative
            />
            <Dimmer active={loading}>
                <Loader size='massive'>Loading</Loader>
            </Dimmer>
            <Segment basic>
                <p>Business ID: {businessid}</p>
                {currentObject ? <BusinessDetailsHolder data={currentObject} /> : null}
            </Segment>
            <Segment basic>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                            <Button secondary onClick={() => alert("Not available yet")}>Hide Business</Button>
                        </Grid.Column>
                        <Grid.Column>
                            <Button negative onClick={() => setIsPopupOpen(true)}>Delete Business</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </DefaultResponsiveContainer>
    )
}