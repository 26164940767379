import { Form } from "semantic-ui-react";

interface ModifiableGroupProps{
    fieldName: string;
    currentValue: string | undefined;
    onValueChange: (newValue: string) => void;
}

export function ModifiableGroup({fieldName, currentValue, onValueChange}: ModifiableGroupProps){
    return (
        <Form.Field
            controlId="floatingTextarea"
            label={fieldName}
            className="me-auto"
        >
            <Form.TextArea
                className="me-auto"
                key={fieldName+"input"}
                as={"textarea"}
                defaultValue={currentValue}
                onChange={({target}) => onValueChange(target.value)}
            />
        </Form.Field>
    );
}