import { useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';

interface TextInputDialogProps {
  show: boolean;
  title: string;
  onSubmit: (val: string) => void;
  onClose: () => void;
}

export default function TextInputDialog(props: TextInputDialogProps) {
    const [value, setValue] = useState("");

    return (
        <Modal open={props.show} onClose={props.onClose} size="mini">
            <Modal.Header>{props.title}</Modal.Header>
            <Modal.Content>
            <Form>
                <Form.Field>
                <label>Field Name</label>
                <input
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    autoFocus
                />
                </Form.Field>
            </Form>
            </Modal.Content>
            <Modal.Actions>
            <Button color="red" onClick={() => { setValue(""); props.onClose(); }}>
                Abort
            </Button>
            <Button color="blue" onClick={() => { props.onSubmit(value); setValue(""); }}>
                Submit
            </Button>
            </Modal.Actions>
        </Modal>
    );
}
