import { useState, createContext, ReactNode, useContext, useEffect } from "react";
import { BusinessCreationData, ValidFieldsBusinessCreationDataUpdate } from "../../models/models";
import { deleteUnregisteredStore, getUnregisteredBusinesses, updateUnregisteredShop } from "../../api/firebase";
import { useGeneralContext } from "./GeneralContext";
import { Timestamp } from "firebase/firestore";

interface CurrentUnregisteredBusinessContextType{
    currentObject: BusinessCreationData;
    setCurrentSubmissionId: (request_id: string) => void;
    setCurrentObject: (newObject: BusinessCreationData) => void; 
    changeFieldValue: (field: ValidFieldsBusinessCreationDataUpdate, value: string) => void;
    saveChanges: () => Promise<void>;
    addNewField: (field: ValidFieldsBusinessCreationDataUpdate) => void;
    removeNewField: (field: ValidFieldsBusinessCreationDataUpdate) => void;
    deleteObject: () => Promise<void>;
    contextLoading: boolean;
}

const defaultObject = {
    "_ref": "",
    "business_type": "Freelance",
    "tin_number": "", 
    "phone_number": "",
    "business_email": "",
    "store_name": "",
    "shareholders": "",
    "business_sector": "",
    "operating_location": "Rwanda",
    "registered_country": "Rwanda",
    "created_uid": "",
    "submitted_at": {} as Timestamp,
    "submission_id": ""
}

const CurrentUnregisteredBusinessContext = createContext<CurrentUnregisteredBusinessContextType>({
    currentObject: defaultObject,
    setCurrentSubmissionId: (req) => {}, 
    setCurrentObject: (newValue) => {},
    changeFieldValue: (field, value) => {},
    saveChanges: async () => {}, 
    addNewField: (newField) => {}, 
    removeNewField: (newField) => {}, 
    contextLoading: false,
    deleteObject: async () => {}, 
});

interface CurrentUnregisteredShopContextProviderProps{
    children?: ReactNode
}

export default function CurrentUnregisteredShopContextProvider({children}: CurrentUnregisteredShopContextProviderProps){
    const [obj, setObj] = useState<BusinessCreationData>(defaultObject);
    const [currentSubmissionId, setCurrentSubmissionId] = useState<string>("");
    const {currentUser} = useGeneralContext();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            if (currentSubmissionId){
                getUnregisteredBusinesses(currentSubmissionId)
                    .then((resp) => {
                        setLoading(false);
                        if (resp.data && resp.data.length > 0){
                            setObj(resp.data[0]);
                        } else {
                            alert("No data found");
                        }
                    }).catch((err) => {
                        alert(err.message);
                        setLoading(false);
                    })
            }
        }
        getData();
    }, [currentSubmissionId])

    const changeFieldValue = (field: ValidFieldsBusinessCreationDataUpdate, value: any) => {
        setObj((current) => {
            const newObject = {...current};
            newObject[field] = value;            
            return newObject;
        });
    }

    const saveChanges = async () => {
        updateUnregisteredShop(obj, currentUser);
    }

    const addNewField = (newField: ValidFieldsBusinessCreationDataUpdate) => {
        setObj((current) => {
            const newObject = {...current};
            newObject[newField] = "";
            return newObject;
        })
    }

    const removeNewField = (fieldToDelete: ValidFieldsBusinessCreationDataUpdate) => {
        setObj((current) => {
            const newObject = {...current};
            delete newObject[fieldToDelete];
            return newObject;
        })
    }

    const deleteObject = async () => {
        return deleteUnregisteredStore(obj)
    }

    const value = {
        currentObject: obj,
        setCurrentObject: setObj,
        setCurrentSubmissionId: (sub_id: string) => setCurrentSubmissionId(sub_id),
        changeFieldValue: changeFieldValue, 
        saveChanges: saveChanges, 
        addNewField: addNewField, 
        removeNewField: removeNewField,
        deleteObject: deleteObject, 
        contextLoading: loading
    };

    return (
        <CurrentUnregisteredBusinessContext.Provider value={value}>
            {children}
        </CurrentUnregisteredBusinessContext.Provider>
    );
}

export const useUnregisteredBusinessContext = () => useContext(CurrentUnregisteredBusinessContext)