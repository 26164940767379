import { Header } from "semantic-ui-react";
import { DefaultResponsiveContainer } from "../components/DefaultResponsiveContainer";

export default function HomePage(){
    return (
        <DefaultResponsiveContainer>
            <Header as="h1">Dashboard</Header>
            <p>Welcome dear Collegue</p>
        </DefaultResponsiveContainer>
    );
}