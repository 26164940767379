import { createMedia } from '@artsy/fresnel';
import { 
    Container, Menu, Segment, Sidebar, Icon
} from 'semantic-ui-react';
import { DefaultHeader } from '../components/DefaultHeader';
import { ReactNode, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { appName } from '../common/constant';

interface ResponsiveContainerProps{
    children: ReactNode, 
    header?: ReactNode
}

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
});    

function DeskopContainer({children}: ResponsiveContainerProps){
    const [inView, setInView] = useState(false);

    return (
        <Media greaterThan='mobile'>
            <InView onChange={() => setInView(!inView)}>
                <Segment
                    inverted
                    textAlign='center'
                    style={{padding: '0.5em 0em'}}
                    vertical
                >
                    <DefaultHeader/>
                </Segment>
            </InView>
            {children}
        </Media>
    )
}

function MobileContainer({children} : {children: ReactNode}){
    const [sideBarOpened, setSideBarOpened] = useState(false);
    return(
        <Media at='mobile'>
            <Sidebar.Pushable 
                style={{height: '100vh'}}
            >
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    inverted
                    onHide={() => setSideBarOpened(false)}
                    vertical
                    visible={sideBarOpened}
                >
                    <DefaultHeader mobile/>
                </Sidebar>
                <Sidebar.Pusher dimmed={sideBarOpened}>
                    <Segment
                        inverted
                        textAlign='center'
                        style={{ padding: '0.5em 0em' }}
                        vertical
                    >
                        <Container>
                            <Menu inverted pointing secondary size='large'>
                                <Menu.Item onClick={() => setSideBarOpened(true)}>
                                    <Icon name='sidebar'/>
                                </Menu.Item>
                                <Menu.Item as='a' header href="/" position='right'>
                                    {appName}
                                </Menu.Item>  
                            </Menu>
                        </Container>
                    </Segment>
                    {children}
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </Media>
    )
}

export function DefaultResponsiveContainer({children}: ResponsiveContainerProps){
    return (
        <MediaContextProvider>
            <DeskopContainer>{children}</DeskopContainer>
            <MobileContainer>{children}</MobileContainer>
        </MediaContextProvider>
    )
}