import { useState, createContext, ReactNode, useContext, useEffect } from "react";
import { RegisteredBusiness } from "../../models/models";
import { getRegisteredBusiness } from "../../api/firebase";

interface CurrentRegisteredBusinessContextType{
    currentObject: RegisteredBusiness | undefined;
    loading: boolean;
    setCurrentBusinessId: (businessId: string) => void;
}

const CurrentRegisteredBusinessContext = createContext<CurrentRegisteredBusinessContextType>({
    currentObject: undefined, 
    loading: false,
    setCurrentBusinessId: () => {}
});

interface CurrentRegisteredShopContextProviderProps{
    children?: ReactNode
}

export function CurrentRegisteredShopContextProvider({children}: CurrentRegisteredShopContextProviderProps){
    const [obj, setObj] = useState<RegisteredBusiness | undefined>(undefined);
    const [currentBusinessId, setCurrentBusinessId] = useState<string>("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getData = async (businessId: string) => {
            if (businessId.length === 0) return;
            setLoading((_) => true);
            getRegisteredBusiness(businessId)
                .then((resp) => {
                    setObj(resp.data[0] as RegisteredBusiness);
                    setLoading((_) => false);
                })
                .catch((err) => {
                    alert(err.message);
                    setLoading((_) => false);
                });
        }
        getData(currentBusinessId);
    }, [currentBusinessId]);

    const value = {
        currentObject: obj,
        setCurrentBusinessId: setCurrentBusinessId, 
        loading: loading
    }
    return (
        <CurrentRegisteredBusinessContext.Provider value={value}>
            {children}
        </CurrentRegisteredBusinessContext.Provider>
    );
}

export const useRegisteredBusinessContext = () => useContext(CurrentRegisteredBusinessContext)