import { Button, Container, GridColumn, GridRow } from "semantic-ui-react";
import { ModifiableGroup } from "./ModifiableInputGroup";


interface DeleteableInputGroupProps{
    objKey: string;
    currentValue: string | undefined;
    onValueChange: (val: string) => void
    onDelete: () => void;
}

export function DeleteableInputGroup(props: DeleteableInputGroupProps) {
    return (
        <Container className='mb-4'>
            <GridRow className='align-items-center'>
                <GridColumn sm={10}>
                    <ModifiableGroup 
                        fieldName={props.objKey} 
                        currentValue={props.currentValue} 
                        onValueChange={props.onValueChange}
                    />
                </GridColumn>
                <GridColumn sm={1}>
                    <Button     
                        variant="outline-danger" 
                        onClick={props.onDelete}
                    >
                        Delete
                    </Button>
                </GridColumn>
            </GridRow>
        </Container>
    );
}