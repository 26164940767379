import { useEffect, useState } from "react";
import { Button, Dimmer, Loader, Message, Table } from "semantic-ui-react";
import { RegisteredBusiness } from "../models/models";
import { getRegisteredBusiness } from "../api/firebase";
import { Link } from "react-router-dom";

function RegisteredBusinessTableRow({data}: {data: RegisteredBusiness}) {
    return (
        <Table.Row>
            <Table.Cell>{data.store_id}</Table.Cell>
            <Table.Cell>{data.store_name}</Table.Cell>
            <Table.Cell>
                <Button 
                    as={Link}
                    to={`/registered_business/${data.store_id}`}
                    primary
                    icon="eye"
                />
            </Table.Cell>
        </Table.Row>
    )
}

export function RegisteredBusinessesTable(){
    const [businesses, setBusinesses] = useState<RegisteredBusiness[]>([]);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {   
        const fetchData = async () => {
            setLoading((_) => true);
            getRegisteredBusiness()
                .then((resp) => {
                    setLoading((_) => false);
                    setErrorMsg(() => "");
                    if (resp.status === "success"){
                        setBusinesses(resp.data as RegisteredBusiness[]);
                    } else {
                        setErrorMsg(() => resp.message || resp.status || "Unidentified error");
                    }
                })
                .catch((err) => {
                    setLoading((_) => false);
                    setErrorMsg(() => err.message);
                });
        }
        fetchData();
    }, []);

    return (
        <>
            <Dimmer active={loading}>
                <Loader size='massive'>Loading</Loader>
            </Dimmer>
            <Table striped selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Business ID</Table.HeaderCell>
                        <Table.HeaderCell>Business Name</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {businesses.map((data) => (
                        <RegisteredBusinessTableRow key={data.store_id} data={data} />
                    ))}
                </Table.Body>
                <Table.Footer fullWidth>
                    <Table.Row>
                        <Table.Cell colSpan="2">
                        {errorMsg.length > 0 && <Message negative>{errorMsg}</Message>}
                        </Table.Cell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </>
    )
}